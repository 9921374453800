var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vs-row',{style:({ paddingTop: '2%' }),attrs:{"justify":"right"}},[(_vm.products.length > 0)?_c('v-btn',{attrs:{"outlined":"","color":_vm.COLORS.SECONDARY},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('span',{staticClass:"material-symbols-outlined",style:({ color: _vm.COLORS.SECONDARY })},[_vm._v(" tune ")]),_c('h5',{style:({ color: _vm.COLORS.SECONDARY, paddingTop: '10px' })},[_vm._v("Filter & Sort")])]):_vm._e()],1),_c('vs-row',[(_vm.drawer)?_c('vs-col',{attrs:{"w":12}},[_c('v-list',[_c('v-list-group',{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"18px"}},[_vm._v("Category")])],1)]},proxy:true}],null,false,2170144737),model:{value:(_vm.filterCategory),callback:function ($$v) {_vm.filterCategory=$$v},expression:"filterCategory"}},[_c('div',{staticStyle:{"text-align":"left"}},[_c('v-btn',{attrs:{"outlined":"","rounded":"","filterCategory":_vm.filterCategory == 'all'},on:{"click":function($event){_vm.filterCategory = 'all';
            _vm.forceUpdate();}}},[_vm._v(" All ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterCategory":_vm.filterCategory == 'Tops'},on:{"click":function($event){_vm.filterCategory = 'Tops';
            _vm.forceUpdate();}}},[_vm._v(" Tops ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterCategory":_vm.filterCategory == 'Bottoms'},on:{"click":function($event){_vm.filterCategory = 'Bottoms';
            _vm.forceUpdate();}}},[_vm._v(" Bottoms ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterCategory":_vm.filterCategory == 'Dresses'},on:{"click":function($event){_vm.filterCategory = 'Dresses';
            _vm.forceUpdate();}}},[_vm._v(" Dresses ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterCategory":_vm.filterCategory == 'Dress Sets'},on:{"click":function($event){_vm.filterCategory = 'Dress Sets';
            _vm.forceUpdate();}}},[_vm._v(" Dress Sets ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterCategory":_vm.filterCategory == 'Best Sellers'},on:{"click":function($event){_vm.filterCategory = 'Best Sellers';
            _vm.forceUpdate();}}},[_vm._v(" Best Sellers ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterCategory":_vm.filterCategory == 'New Collection'},on:{"click":function($event){_vm.filterCategory = 'New Collection';
            _vm.forceUpdate();}}},[_vm._v(" New Collection ")])],1)]),_c('v-list-group',{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"18px"}},[_vm._v("Sort")])],1)]},proxy:true}],null,false,2774513227),model:{value:(_vm.filterSort),callback:function ($$v) {_vm.filterSort=$$v},expression:"filterSort"}},[_c('div',{staticStyle:{"text-align":"left"}},[_c('v-btn',{attrs:{"outlined":"","rounded":"","filterSort":_vm.filterSort == 'plh'},on:{"click":function($event){$event.preventDefault();return _vm.filterLH()}}},[_vm._v(" Price: Low-High ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterSort":_vm.filterSort == 'phl'},on:{"click":function($event){$event.preventDefault();return _vm.filterHL()}}},[_vm._v(" Price: High-Low ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterSort":_vm.filterSort == 'la'},on:{"click":function($event){$event.preventDefault();return _vm.filterDL()}}},[_vm._v(" Latest ")]),_c('v-btn',{attrs:{"outlined":"","rounded":"","filterSort":_vm.filterSort == 'ol'},on:{"click":function($event){$event.preventDefault();return _vm.filterDO()}}},[_vm._v(" Oldest ")])],1)])],1)],1):_vm._e(),_c('vs-col',{style:({ padding: '2% 0' }),attrs:{"w":12}},[_c('vs-row',{attrs:{"justify":"left"}},_vm._l((_vm.showProducts),function(item){return _c('vs-col',{key:item.productId,staticStyle:{"font-size":"15px","padding":"0.5%"},attrs:{"w":_vm.$vuetify.breakpoint.smAndDown ? 6 : 3}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
            name: 'EditProduct',
            params: { productId: item.productId },
          }}},[_c('v-card',{style:({ paddingBottom: '10%' }),attrs:{"tile":"","flat":""}},[_c('v-img',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? '250px' : '400px',"src":item.productImages[0]},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"width":"100%","height":"100%","type":"image"}})]},proxy:true}],null,true)}),_c('div',{style:({ padding: '2%' })},[_c('h5',[_vm._v(" "+_vm._s(item.productTitle)+" ")]),_c('div',[_c('strong',[_c('i',{staticClass:"fa-solid fa-bangladeshi-taka-sign"}),_vm._v(" "+_vm._s(item.productPrice))])]),(item.productQuantity == 0)?_c('div',{staticStyle:{"color":"crimson"}},[_c('strong',[_vm._v("Out of Stock")])]):_vm._e(),(item.productColors.length)?_c('div',[_vm._v("Colors: "+_vm._s(item.productColors.join(', ')))]):_vm._e(),_c('div',[_vm._v("Quantity: "+_vm._s(item.productQuantity))]),(item.productSizeChart)?_c('div',{style:({ display: 'flex', alignItems: 'center' })},[_vm._v("Size Chart: "),_c('span',{staticClass:"material-symbols-outlined",style:({ color: _vm.COLORS.GREEN })},[_vm._v(" priority ")])]):_vm._e(),(!item.productSizeChart)?_c('div',{style:({ display: 'flex', alignItems: 'center' })},[_vm._v("Size Chart: "),_c('span',{staticClass:"material-symbols-outlined",style:({ color: _vm.COLORS.RED })},[_vm._v(" disabled_by_default ")])]):_vm._e(),_c('div',{staticStyle:{"font-size":"15px"}},[_c('strong',[_vm._v(_vm._s(item.productTags.join(', ')))])]),_c('vs-row',{attrs:{"justify":"right"}},[_c('v-btn',{attrs:{"small":"","depressed":"","dark":"","rounded":"","color":_vm.COLORS.RED},on:{"click":function($event){$event.preventDefault();return _vm.deleteProduct(item.productId)}}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)}),1)],1)],1),_c('Alert',{attrs:{"snackbar":_vm.alert,"text":_vm.alertMessage,"color":_vm.alertColor},on:{"updateSnackbar":_vm.closeSnackbar}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }