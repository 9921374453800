<template>
  <div>
    <vs-row justify="right" :style="{ paddingTop: '2%' }">
      <v-btn v-if="products.length > 0" outlined :color="COLORS.SECONDARY" @click.stop="drawer = !drawer">
        <span :style="{ color: COLORS.SECONDARY }" class="material-symbols-outlined">
          tune
        </span>
        <h5 :style="{ color: COLORS.SECONDARY, paddingTop: '10px' }">Filter & Sort</h5>
      </v-btn>
    </vs-row>
    <vs-row>
      <vs-col v-if="drawer" :w="12">
        <v-list>
          <v-list-group v-model="filterCategory" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="font-size: 18px; ">Category</v-list-item-title>
              </v-list-item-content>
            </template>
            <div style="text-align: left;">
              <v-btn outlined rounded :filterCategory="filterCategory == 'all'" @click="
                filterCategory = 'all';
              forceUpdate();
              ">
                All
              </v-btn>
              <v-btn outlined rounded :filterCategory="filterCategory == 'Tops'" @click="
                filterCategory = 'Tops';
              forceUpdate();
              ">
                Tops
              </v-btn>
              <v-btn outlined rounded :filterCategory="filterCategory == 'Bottoms'" @click="
                filterCategory = 'Bottoms';
              forceUpdate();
              ">
                Bottoms
              </v-btn>
              <v-btn outlined rounded :filterCategory="filterCategory == 'Dresses'" @click="
                filterCategory = 'Dresses';
              forceUpdate();
              ">
                Dresses
              </v-btn>
              <v-btn outlined rounded :filterCategory="filterCategory == 'Dress Sets'" @click="
                filterCategory = 'Dress Sets';
              forceUpdate();
              ">
                Dress Sets
              </v-btn>
              <v-btn outlined rounded :filterCategory="filterCategory == 'Best Sellers'" @click="
                filterCategory = 'Best Sellers';
              forceUpdate();
              ">
                Best Sellers
              </v-btn>
              <v-btn outlined rounded :filterCategory="filterCategory == 'New Collection'" @click="
                filterCategory = 'New Collection';
              forceUpdate();
              ">
                New Collection
              </v-btn>
            </div>
          </v-list-group>
          <v-list-group v-model="filterSort" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="font-size: 18px;">Sort</v-list-item-title>
              </v-list-item-content>
            </template>
            <div style="text-align: left;">
              <v-btn @click.prevent="filterLH()" outlined rounded :filterSort="filterSort == 'plh'">
                Price: Low-High
              </v-btn>
              <v-btn @click.prevent="filterHL()" outlined rounded :filterSort="filterSort == 'phl'">
                Price: High-Low
              </v-btn>
              <v-btn @click.prevent="filterDL()" outlined rounded :filterSort="filterSort == 'la'">
                Latest
              </v-btn>
              <v-btn @click.prevent="filterDO()" outlined rounded :filterSort="filterSort == 'ol'">
                Oldest
              </v-btn>
            </div>
          </v-list-group>
        </v-list>
      </vs-col>
      <vs-col :style="{ padding: '2% 0' }" :w="12">
        <vs-row justify="left">
          <vs-col :w="$vuetify.breakpoint.smAndDown ? 6 : 3" style=" font-size: 15px; padding: 0.5%;"
            v-for="item in showProducts" v-bind:key="item.productId">
            <router-link style="text-decoration: none" :to="{
              name: 'EditProduct',
              params: { productId: item.productId },
            }">
              <v-card tile flat :style="{ paddingBottom: '10%' }">
                <v-img :height="$vuetify.breakpoint.smAndDown ? '250px' : '400px'" v-bind:src="item.productImages[0]">
                  <template v-slot:placeholder>
                    <v-skeleton-loader class="mx-auto" width="100%" height="100%" type="image"></v-skeleton-loader>
                  </template>
                </v-img>
                <div :style="{ padding: '2%' }">
                  <h5> {{ item.productTitle }} </h5>
                  <div> <strong><i class="fa-solid fa-bangladeshi-taka-sign"></i> {{ item.productPrice }}</strong>
                  </div>
                  <div style="color: crimson" v-if="item.productQuantity == 0"> <strong>Out of Stock</strong> </div>
                  <div v-if="item.productColors.length">Colors: {{ item.productColors.join(', ') }}</div>
                  <div>Quantity: {{ item.productQuantity }}</div>
                  <div :style="{ display: 'flex', alignItems: 'center' }" v-if="item.productSizeChart">Size Chart:
                    <span :style="{ color: COLORS.GREEN }" class="material-symbols-outlined"> priority </span>
                  </div>
                  <div :style="{ display: 'flex', alignItems: 'center' }" v-if="!item.productSizeChart">Size Chart:
                    <span :style="{ color: COLORS.RED }" class="material-symbols-outlined">
                      disabled_by_default
                    </span>
                  </div>
                  <div style="font-size: 15px"> <strong>{{ item.productTags.join(', ') }}</strong> </div>
                  <vs-row justify="right">
                    <v-btn small depressed dark rounded :color="COLORS.RED"
                      @click.prevent="deleteProduct(item.productId)">
                      Delete
                    </v-btn>
                  </vs-row>
                </div>
              </v-card>
            </router-link>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <Alert :snackbar="alert" :text="alertMessage" :color="alertColor" @updateSnackbar="closeSnackbar" />
  </div>
</template>

<script>
import { COLORS } from '@/plugins/branding.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { toggleLoading } from '@/components/js/loading.js';

export default {
  name: 'Products',
  props: ['products'],
  components: {
    Alert: () => import('@/components/Alert.vue'),
  },
  data: () => ({
    drawer: false,
    filterCategory: 'all',
    showProducts: [],
    filterSort: null,
    COLORS,
    alert: false,
    alertColor: null,
    alertMessage: null,
  }),
  async mounted() {
    await this.forceUpdate();
  },
  methods: {
    closeSnackbar(bool) {
      this.alert = bool;
    },
    toggleLoading(isLoading) {
      toggleLoading(this, isLoading);
    },
    forceUpdate() {
      this.showProducts = [];
      if (this.filterCategory !== 'all') {
        this.showProducts = this.products.filter(product => product.productTags.includes(this.filterCategory));
      } else {
        this.showProducts = this.products;
      }
    },
    deleteProduct(payload) {
      this.toggleLoading(true);
      const product = this.showProducts.find(item => item.productId === payload);
      if (product && product.productImages) {
        product.productImages.forEach(async (image) => {
          try {
            const imageRef = firebase.storage().refFromURL(image);
            await imageRef.delete();
          } catch (err) {
            console.error(`Failed to delete image ${image}:`, err);
          }
        });
      }
      if (product && product.productSizeChart) {
        try {
          const imageRef = firebase.storage().refFromURL(product.productSizeChart);
          imageRef.delete();
        } catch (err) {
          console.error(`Failed to delete image ${image}:`, err);
        }
      }
      this.$store.dispatch('deleteProduct', payload);
      this.showProducts = this.showProducts.filter(item => item.productId !== payload);
      this.toggleLoading(false);
      this.alertColor = COLORS.GREEN;
      this.alertMessage = 'Product successfully deleted';
      this.alert = true;
    },
    filterLH() {
      this.showProducts.sort((a, b) =>
        Number(a.productPrice) > Number(b.productPrice) ? 1 : -1
      );
    },
    filterHL() {
      this.showProducts.sort((a, b) =>
        Number(a.productPrice) > Number(b.productPrice) ? -1 : 1
      );
    },
    filterDL() {
      this.showProducts.sort(
        (a, b) => new Date(b.productDate) - new Date(a.productDate)
      );
    },
    filterDO() {
      this.showProducts.sort(
        (a, b) => new Date(a.productDate) - new Date(b.productDate)
      );
    },
  },
};
</script>
